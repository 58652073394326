/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
  */
// Soft UI Dashboard PRO React layouts

// CANDIDATE
// CANDIDATE
// CANDIDATE
// CANDIDATE
import { ReactSession } from 'react-client-session';

// import BlankView from "layouts/blank";
// import SignInDefault from "layouts/authentication/sign-in/default";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignInStudent from "layouts/authentication/sign-in/student";
import SignUpIllustration from "layouts/authentication/sign-up/illustration";

import Default from "layouts/dashboards/default";
import StudentDashboard from "layouts/dashboards/student";
// import SavedLessons from 'layouts/pages/savedLessons'

import PublicProfile from "layouts/pages/profile/public-profile";
import ProfileCandidate from "layouts/pages/profile/profile-overview";
import ProfileRecruiter from "layouts/pages/profile/profile-recruiter";
import ApplicationOverview from "layouts/pages/profile/application-overview";
import Wizard from "layouts/applications/wizard";

import JobList from "layouts/pages/account/joblist";
import JobListForYou from "layouts/pages/account/joblist-foryou";
import JobListSaved from "layouts/pages/account/joblist-saved";

import JobDetail from "layouts/pages/account/detailjob";
import Apply from "layouts/pages/account/apply";
import JobApply from "layouts/applications/job-apply";

import ApplicationsCandidate from "layouts/pages/profile/applications-list";
import candidateApplications from "layouts/pages/profile/candidate-applications";

import Discounts from "layouts/pages/discounts";
import Refer from "layouts/pages/referral";
import Settings from "layouts/pages/account/settings-candidate";
import SettingsStudent from "layouts/pages/account/settings-student";
import AiTutor from "layouts/pages/account/aiTutor";
import Assessment from "layouts/pages/account/assessment";
import Invoice from "layouts/pages/profile/invoices";
import ClientInvoices from "layouts/pages/clientInvoices";

import CandidateLogout from "layouts/pages/candidateLogout/CandidateLogout";

// COMPANY
// COMPANY
// COMPANY
// COMPANY
import SignInCompany from "layouts/authentication/sign-in/company";
import SignUpCompany from "layouts/authentication/sign-up/company";

// SOCIAL
import SignUnLinkedin from "layouts/authentication/social/linkedin";

import Join from "layouts/authentication/sign-up/join";
import StudentJoin from "layouts/authentication/sign-up/student/join";

import CompanyDashboard from "layouts/dashboards/company";

import CompanyDashboardActived from "layouts/dashboards/company/actived";
import CompanyDashboardPaused from "layouts/dashboards/company/paused";
import CompanyDashboardClosed from "layouts/dashboards/company/closed";
import CompanyDashboardEmpty from "layouts/dashboards/companyEmpty";

import ProfileCompany from "layouts/pages/profile/profile-company";
import CompanyWizard from "layouts/applications/company-wizard";

import NewJob from "layouts/applications/newjob";
import RNewJob from "layouts/applications/recruiter-newjob";
import Manage from "layouts/pages/applications-list";
import RecruiterManage from "layouts/pages/recruiter-applications";

import TotalApplications from "layouts/pages/total-applications";

import Support from "layouts/pages/account/support";
import CompanySettings from "layouts/pages/account/settings-company";


import Snooze from "layouts/authentication/snooze/illustration";
import Unsuscribe from "layouts/authentication/unsubscribe";
import Claim from "layouts/authentication/claim/illustration";
import Claimed from "layouts/authentication/claimed/illustration";
import ResetIllustration from "layouts/authentication/reset-password/illustration";
import ResetPasswordStudent from "layouts/authentication/reset-password/student";
import NewIllustration from "layouts/authentication/new-password/illustration";
import NewPasswordStudent from "layouts/authentication/new-password/student";
import NewerIllustration from "layouts/authentication/change-password/illustration";
import Activate from "layouts/authentication/error/activate";
import RecruiterActivate from "layouts/authentication/error/recruiterActivate";
import MagicLink from "layouts/authentication/magic-link";
import Validated from "layouts/authentication/error/validated";
import Error404 from "layouts/authentication/error/404";

import CvBuilder from "layouts/cvBuilder";
import TechTrends from "layouts/techTrends";

// Soft UI Dashboard PRO React icons
import Office from "examples/Icons/Office";
import SettingsIcon from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";


// RECRUITER
// RECRUITER
// RECRUITER
// RECRUITER
import RecruiterCreateCandidate from "layouts/authentication/create/candidate";
import SignInRecruiter from "layouts/authentication/sign-in/recruiter";
import SignUpRecruiter from "layouts/authentication/sign-up/recruiter";
import RecruiterDashboard from "layouts/dashboards/recruiter";
import RecruiterJobs from "layouts/pages/account/joblistRecruiter";
import PublicJobs from "layouts/pages/account/joblistPublic";
import Utms from "layouts/pages/utm";
import Reports from "layouts/pages/report";
import RecruiterApplications from "layouts/pages/applicationsRecruiter";
import TalentFinder from "layouts/pages/searchRecruiter";
import SimilarCandidates from "layouts/pages/similarCandidates";
import CompanyFinder from "layouts/pages/searchCompany";
import RecruiterFolders from "layouts/recruiterFolders/recruiterFolders";
import ClientFolders from "layouts/clientFolders/clientFolders";

import CompanySourcingContent from "layouts/companySourcingContent/companySourcingContent";
import RecruiterSourcingContent from "layouts/recruiterSourcingContent/recruiterSourcingContent";
import Suggested from "layouts/pages/suggested";
import RecruiterAISourcing from "layouts/aiSourcing";
import AISourcingDecline from "layouts/aiSourcing/decline";
import RecruiterFolderContent from "layouts/recruiterFolders/folderContent/folderContent";
import ClientFolderContent from "layouts/clientFolders/folderContent/clientFolderContent";

import RecruiterCompanyWizard from "layouts/applications/recruiter-company-wizard";
import RecruiterWizard from "layouts/applications/recruiter-wizard";
import RecruiterCreateCompany from "layouts/applications/recruiter-create-company";
import RecruiterLogout from "layouts/pages/recruiterLogout/RecruiterLogout";
import ApplicationOverviewRecruiter from "layouts/pages/profile/application-overview-recruiter";
import RecruiterApplicationEdit from "layouts/applications/recruiter-application-edit"
import RecruiterApplicationCreate from "layouts/applications/recruiter-application-create"
import RecruiterEditCandidateWizard from "layouts/pages/profile/recruiter-EditCandidateWizard";

import ManagerInvoices from "layouts/pages/managerInvoices/ManagerInvoices"
import ManagersAddToTeam from "layouts/pages/managersAddToTeam/ManagersAddToTeam"
import ConversionData from "layouts/pages/conversionData/ConversionData"

import RecruiterSuccessRate from "layouts/pages/recruiterSucessRate/recruiterSuccessRate"

// Icons
import LogoutIcon from '@mui/icons-material/Logout';
import CompanyLogout from "layouts/pages/companyLogout/CompanyLogout";
import StudentLogout from "layouts/pages/studentLogout/StudentLogout";
import Videos from "layouts/pages/videos/Videos";
import CompanyDiscounts from "layouts/pages/companyDiscounts/companyDiscounts";
import JobFeedback from 'layouts/pages/jobFeedback';
import StripeSuccess from 'layouts/dashboards/default/levelUpView/stripeSuccess/StripeSuccess';
import StripeCancel from 'layouts/dashboards/default/levelUpView/stripeCancel/StripeCancel';
// import Quizzes from 'layouts/pages/tests';
import Newsletters from 'layouts/pages/newsletters';
import NewslettersIssues from 'layouts/pages/newsletters/NewslettersIssues';
import NewslettersIssueDetail from 'layouts/pages/newsletters/NewsletterIssueDetail';
import MyCoaches from 'layouts/pages/coach/myCoaches';
// import RequestCoach from 'layouts/pages/coach/request';

ReactSession.setStoreType("localStorage");

const invoicesNotificationsCount = ReactSession.get("invoicesNotificationsCount") ? ReactSession.get("invoicesNotificationsCount") : 0;
let invoicesNotificationActive = "";
if (invoicesNotificationsCount) invoicesNotificationActive = "active";

const candidate = ReactSession.get("candidate");

const routes = [
  {
    name: "Candidate Log In",
    route: "/t/login",
    component: SignInIllustration,
  },
  {
    name: "Student Log In",
    route: "/login",
    component: SignInStudent,
  },
  {
    name: "Company Log In",
    route: "/c/login",
    component: SignInCompany,
  },
  {
    name: "Company Log In",
    route: "/r/login",
    component: SignInRecruiter,
  },
  {
    name: "Candidate Sign Up",
    route: "/t/signup",
    component: SignUpIllustration,
  },
  {
    name: "Company Sign Up",
    route: "/c/signup",
    component: SignUpCompany,
  },
  {
    name: "Recruiter Sign Up",
    route: "/r/signup",
    component: SignUpRecruiter,
  },
  {
    name: "Create Candidate",
    route: "/r/createCandidate",
    component: RecruiterCreateCandidate,
  },
  {
    name: "Linkedin Log In",
    route: "/linkedin",
    component: SignUnLinkedin,
  },
  {
    name: ".Application Detail",
    route: "/application/:appId",
    component: ApplicationOverview,
  },
  {
    name: ".Application Detail",
    route: "/r/application/:appId",
    component: ApplicationOverviewRecruiter,
  },
  {
    name: "Edit application",
    route: "/r/applicationEdit/:appId",
    component: RecruiterApplicationEdit,
  },
  {
    name: "Create application",
    route: "/r/createApplication",
    component: RecruiterApplicationCreate,
  },
  {
    name: "Reset Password",
    route: "/reset-password",
    component: ResetIllustration,
  },
  {
    name: "Reset Password",
    route: "/forgot-password",
    component: ResetPasswordStudent,
  },
  {
    name: "New Password",
    route: "/new-password",
    component: NewIllustration,
  },
  {
    name: "New Password",
    route: "/set-password",
    component: NewPasswordStudent,
  },
  {
    name: "Change Password",
    route: "/dashboard/t/change-password",
    component: NewerIllustration,
  },
  {
    name: "Snooze",
    route: "/snooze",
    component: Snooze,
  },
  {
    name: "Unsuscribe",
    route: "/unsubscribe",
    component: Unsuscribe,
  },
  {
    name: "User profile",
    route: "/t/profile/:userId",
    component: ProfileCandidate,
  },
  {
    name: "Public profile",
    route: "/profile/:userId",
    component: PublicProfile,
  },
  {
    name: "User profile",
    route: "/r/profile/:userId",
    component: ProfileCandidate,
  },
  {
    name: "User profile",
    route: "/r/recruiter",
    component: ProfileRecruiter,
  },
  {
    name: "Update Recruiter",
    route: "/r/recruiter/wizard",
    component: RecruiterWizard,
  },
  {
    name: "Job Details",
    route: "/job/",
    component: JobDetail,
  },
  {
    name: "Jobs",
    route: "/jobs",
    component: PublicJobs,
  },{
    name: "Jobs",
    route: "/r/UTMs",
    component: Utms,
  },{
    name: "Jobs",
    route: "/r/reports",
    component: Reports,
  },
  {
    name: "Activate Account",
    route: "/activate",
    component: Activate,
  },
  {
    name: "Recruiter Activate Account",
    route: "/recruiterActivate",
    component: RecruiterActivate,
  },
  {
    name: "Magic Link",
    route: "/m/:code",
    component: MagicLink,
  },
  {
    name: "Account Validated",
    route: "/validated",
    component: Validated,
  },
  {
    name: "Claim Account",
    route: "/t/claim",
    component: Claim,
  },
  {
    name: "Claimed Account",
    route: "/t/claimed",
    component: Claimed,
  },
  {
    name: "Account Wizard",
    route: "/t/wizard",
    component: Wizard,
  },
  {
    name: "Courses",
    route: "/t/dashboard/courses/:courseId",
    key: "/t/course",
    component: Default,
  },
  {
    name: "Courses",
    route: "/t/dashboard/courses/:courseId/:moduleId",
    key: "/t/module",
    component: Default,
  },
  {
    name: "Courses",
    route: "/t/dashboard/courses/:courseId/:moduleId/:lessonId",
    key: "/t/lesson",
    component: Default,
  },
  {
    name: "Newsletters",
    route: "/t/newsletters",
    component: Newsletters,
  },
  {
    name: "Newsletters issues",
    route: "/t/newsletters/:nlType",
    component: NewslettersIssues,
  },
  {
    name: "Newsletters issue detail",
    route: "/t/newsletters/:nlType/:nlId",
    component: NewslettersIssueDetail,
  },
  {
    name: "Courses",
    route: "/dashboard/courses/:courseId",
    key: "/course",
    component: StudentDashboard,
  },
  {
    name: "Courses",
    route: "/dashboard/courses/:courseId/:moduleId",
    key: "/module",
    component: StudentDashboard,
  },
  {
    name: "Courses",
    route: "/dashboard/courses/:courseId/:moduleId/:lessonId",
    key: "/lesson",
    component: StudentDashboard,
  },
  {
    name: "Company Account Wizard",
    route: "/c/wizard",
    component: CompanyWizard,
  },
  {
    name: "Invoices",
    route: "/c/invoices",
    component: ClientInvoices,
  },
  {
    name: "Job Apply Old",
    route: "/job/apply-old",
    component: Apply,
  },
  {
    name: "Job Apply",
    route: "/job/apply",
    component: JobApply,
  },
  {
    name: "CV builder",
    route: "/r/cvbuilder/:cid",
    component: CvBuilder,
  },
  {
    name: "Tech Trends",
    route: "/tools/techtrends",
    component: TechTrends,
  },
  // COMPANY
  {
    name: "Dashboard",
    route: "/c/dashboard",
    component: CompanyDashboard,
  },
  {
    name: "Join Tecla",
    route: "/c/join",
    component: Join,
  },
  {
    name: "Join Tecla",
    route: "/join",
    component: StudentJoin,
  },
  // 
  // 
  // 
  {
    name: "Dashboard Empty state",
    route: "/c/dashboard/empty",
    component: CompanyDashboardEmpty,
  },
  {
    name: "Profile",
    route: "/c/profile",
    component: ProfileCompany,
  },
  {
    name: "Create Job",
    route: "/c/create-a-job",
    component: NewJob,
  },
  {
    name: "Active jobs",
    route: "/c/active-jobs",
    component: CompanyDashboardActived,
  },
  {
    name: "Paused jobs",
    route: "/c/paused-jobs",
    component: CompanyDashboardPaused,
  },
  {
    name: "Closed jobs",
    route: "/c/closed-jobs",
    component: CompanyDashboardClosed,
  },
  {
    name: "Support",
    route: "/c/support",
    component: Support,
  },
  {
    name: "Applications",
    route: "/c/manage",
    component: Manage,
  },
  {
    name: "Applicants",
    route: "/c/review",
    component: TotalApplications,
  },
  {
    name: "Settings",
    route: "/c/settings",
    component: CompanySettings,
  },
  {
    name: "Applicants",
    route: "/c/applicants",
    component: Error404,
  },
  {
    name: "Sourcing",
    route: "/c/sourcing/:id",
    component: CompanySourcingContent,
  },
  {
    name: "Company discounts",
    route: "/c/discounts",
    component: CompanyDiscounts,
  },
  // recruiter
  {
    name: "Dashboard",
    route: "/r/dashboard",
    component: RecruiterDashboard,
  },
  {
    name: "Jobs",
    route: "/r/jobs",
    component: RecruiterJobs,
  },
  {
    name: "Applications",
    route: "/r/applications",
    component: RecruiterApplications,
  },
  {
    name: "Profile",
    route: "/r/company/:companyId",
    component: ProfileCompany,
  },
  {
    name: "Company Account Wizard",
    route: "/r/companyWizard",
    component: RecruiterCompanyWizard,
  },
  {
    name: "Company Create",
    route: "/r/createCompany",
    component: RecruiterCreateCompany,
  },
  {
    name: "Recruiter Manage",
    route: "/r/manage",
    component: RecruiterManage,
  },
  {
    name: "Applications",
    route: "/r/applications/:cId",
    component: candidateApplications,
  },
  {
    name: "Folders",
    route: "/r/folders",
    component: RecruiterFolders,
  },
  {
    name: "Client folders",
    route: "/r/client-folders",
    component: ClientFolders,
  },
  {
    name: "Folders",
    route: "/folders/:id",
    component: ClientFolderContent,
  },
  {
    name: "Create Job",
    route: "/r/newjob",
    component: RNewJob,
  },
  {
    name: "Folders",
    route: "/r/folders/:id",
    component: RecruiterFolderContent,
  },
  {
    name: "AI Sourcing",
    route: "/r/ai-sourcing/:id",
    component: RecruiterAISourcing,
  },
  {
    name: "AI Sourcing Decline",
    route: "/declineInvites",
    component: AISourcingDecline,
  },
  {
    name: "Sourcing",
    route: "/r/sourcing/:id",
    component: RecruiterSourcingContent,
  },
  {
    name: "Suggested",
    route: "/r/suggested/:jId",
    component: Suggested,
  },
  {
    name: "Talent",
    route: "/r/talent",
    component: TalentFinder,
  },
  {
    name: "Talent",
    route: "/r/talent/:cId/similar",
    component: SimilarCandidates,
  },
  {
    name: "Account Wizard",
    route: "/r/editCandidateWizard/:candidateId",
    component: RecruiterEditCandidateWizard,
  },
  {
    name: "Company",
    route: "/r/companies",
    component: CompanyFinder,
  },
  {
    name: "Invoices",
    route: "/r/invoices",
    component: ManagerInvoices,
  },
  {
    name: "Add to team",
    route: "/r/add-to-team/:candidateId",
    component: ManagersAddToTeam,
  },
  {
    name: "Add to team",
    route: "/r/conversion-data",
    component: ConversionData,
  },
  {
    name: "Recruiter log out",
    route: "/r/logout",
    component: RecruiterLogout,
  },
  {
    name: "Applications",
    route: "/r/feedback/:jobId",
    component: JobFeedback,
  },
  {
    name: "Success rate",
    route: "/r/successRate",
    component: RecruiterSuccessRate,
  },
  {
    name: "Recruiter log out",
    route: "/c/logout",
    component: CompanyLogout,
  },
  {
    name: "Student log out",
    route: "/logout",
    component: StudentLogout,
  },
  {
    name: "Stripe success",
    route: "/stripe/success/",
    component: StripeSuccess,
  },
  {
    name: "Stripe cancel",
    route: "/stripe/cancel/",
    component: StripeCancel,
  },
  // Student
  {
    name: "Dashboard",
    route: "/dashboard",
    component: StudentDashboard,
  },
  {
    name: "Settings",
    route: "/settings",
    component: SettingsStudent,
  },
  {
    name: "Settings",
    route: "/ai-tutor",
    component: AiTutor,
  },
  {
    name: "Assessment",
    route: "/assessment",
    component: Assessment,
  },
  {
    name: "Perks",
    route: "/perks",
    component: Discounts,
    noCollapse: true
  },
  {
    name: "Workshop",
    route: "/events",
    component: Videos,
    noCollapse: true
  },
  {
    name: "Overview",
    route: "/dashboard/courses",
    key: "/dashboard/overview",
    component: StudentDashboard,
  },
  {
    name: "Resume",
    route: "/dashboard/courses",
    key: "/dashboard/resume",
    component: StudentDashboard,
  },
  {
    name: "Newsletters",
    route: "/newsletters",
    component: Newsletters,
  },
  {
    name: "Newsletters issues",
    route: "/newsletters/:nlType",
    component: NewslettersIssues,
  },
  {
    name: "Newsletters issue detail",
    route: "/newsletters/:nlType/:nlId",
    component: NewslettersIssueDetail,
  },
  {
    name: "My coaches",
    route: "/my-coaches",
    component: MyCoaches,
  },
  // {
  //   name: "Request Coach",
  //   route: "/request-coach",
  //   component: RequestCoach,
  // },
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  // SIDE MENU START
  {
    type: "collapse",
    name: "Dashboard",
    key: "/t/dashboard",
    route: "/t/dashboard",
    href: "/t/dashboard",
    component: Default,
    icon: <SpaceShip size="12px" />,
  },
  { type: "divider", key: "divider-1" },
  {
    type: "collapse",
    name: "Profile",
    key: "/t/profile",
    route: "/t/profile",
    href: "/t/profile",
    component: ProfileCandidate,
    icon: <Office size="12px" />,
  },
  {
    type: "collapse",
    name: "Jobs",
    key: "/t/dashboardJobs",
    icon: <Document size="12px" />,
    noCollapse: false,
    collapse: [
      {
        name: "Browse",
        key: "/t/jobs",
        route: "/t/jobs",
        component: JobList,
      },
      {
        name: "Invitations",
        key: "/t/recommended",
        route: "/t/recommended",
        component: JobListForYou,
      },
      {
        name: "Saved Jobs",
        key: "/t/saved",
        route: "/t/saved",
        component: JobListSaved,
      },
    ],
  },
  {
    type: "collapse",
    name: "Applications",
    key: "/t/applications",
    route: "/t/applications",
    href: "/t/applications",
    component: ApplicationsCandidate,
    icon: <CustomerSupport size="12px" />,
  },
  { type: "divider", key: "divider" },
  {
    type: "collapse",
    name: "Tools",
    key: "tools",
    icon: <SettingsIcon size="12px" />,
    noCollapse: false,
    collapse: [
      // {
      //   name: "Salary Explorer",
      //   key: "salary-explorer",
      //   route: "/t/salary-explorer",
      //   component: Error404,
      // },
      {
        name: "Tech Trends",
        route: "/tools/techtrends",
        component: TechTrends,
      },
    ],
  },
  {
    type: "collapse",
    name: "Resources",
    key: "resources",
    icon: <Document size="12px" />,
    noCollapse: false,
    collapse: [
      {
        name: "Discounts",
        key: "/t/discounts",
        route: "/t/discounts",
        href: "/t/discounts",
        component: Discounts,
      },
      {
        name: "Videos",
        key: "/t/videos",
        route: "/t/videos",
        component: Videos,
      },
      {
        name: "Newsletters",
        key: "/t/newsletters",
        route: "/t/newsletters",
      },
    ],
  },
  { type: "divider", key: "divider-3" },
  {
    type: "collapse",
    name: "Refer",
    key: "/t/refer",
    route: "/t/refer",
    href: "/t/refer",
    // href: "//n.tecla.io/l/referral",
    component: Refer,
    icon: <CustomerSupport size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "/t/settings",
    route: "/t/settings",
    href: "/t/settings",
    component: Settings,
    icon: <SettingsIcon size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Invoices",
    key: "/t/invoices",
    route: "/t/invoices",
    href: "/t/invoices",
    component: Invoice,
    icon: <div className={`notificationsBox ${invoicesNotificationActive}`}><span className="count">{invoicesNotificationsCount}</span><CustomerSupport /></div>,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Log out",
    title: "test title",
    key: "/t/logout",
    route: "/t/logout",
    href: "/t/logout",
    component: CandidateLogout,
    icon: <LogoutIcon fontSize="small" />,
  },
  { type: "divider", key: "divider-2" },
  {
    type: "recruiterAccountDetail",
    name: "InstructionHours",
    title: (candidate?.levelUpEnglishHours > 0) ? `- Coaching hours left: ${candidate?.levelUpEnglishHours}` : '',
    key: "InstructionHours",
  },
];

export default routes;

/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import { ReactSession } from 'react-client-session';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import ReactGA from "react-ga4";
// MUI components
import { Icon, Modal } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from '@mui/material/CircularProgress'
// SUI components
import SuiButton from "components/SuiButton";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAlert from "components/SuiAlert";
// Formik
import { Formik, Form } from "formik";
// NewUser layout schemas for form and form feilds
import SignUp from "layouts/pages/users/new-user/components/SignUp";
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
// Images
import backgroundSignin from "assets/images/contryBg.png";
import MockCandidateAvatar from  "assets/images/mockCandidateAvatar.png"


function SignUpModal() {

    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    const jobId = searchParams.get('id');

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        window.location.replace(`/job?id=${jobId}`);
    };

    // Validation
    const [activeStep, setActiveStep] = useState(0);
    // const steps = getSteps();
    const { formId, formField } = form;
    const currentValidation = validations[activeStep];
    const isLastStep = false;
    let timerid = "";

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const submitForm = async (values, actions) => {
        await sleep(1000);

        // eslint-disable-next-line no-alert
        alert(JSON.stringify(values, null, 2));

        // actions.setSubmitting(false);
        actions.resetForm();

        setActiveStep(0);
    };

    const handleSubmit = (values, actions) => {
        if (isLastStep) {
            submitForm(values, actions);
        } else {
            // setActiveStep(activeStep + 1);
            actions.setTouched({});
            // actions.setSubmitting(false);
        }
    };

    function getCandidate(token) {
        const headers = { "Content-Type": "application/json" };
        if (token) {
            headers.Authorization = `Token ${token}`;
        }
        fetch(`${process.env.REACT_APP_API_ROUTE}/api/candidates/candidate/`, { headers, })
            .then(async response => {
                const data = await response.json();

                ReactSession.set("candidate", data.data);
                ReactSession.set("company", null);
                ReactSession.set("recruiter", null);
                ReactSession.set("secret", true);
                if (!data.data.cv) {
                  window.location.replace("/t/wizard");
                } else {
                  window.location.replace("/t/dashboard");
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }

    function manageLogin(response) {
        $(".suiAlertMsg")?.remove();
        if (response.success) {
            ReactSession.set("user", response.data.user);
            ReactSession.set("token", response.data.access_token);
            getCandidate(response.data.access_token);
        } else {
            $(".alertErrorBox div div").text(response.data.error);
            $(".alertErrorBox").show();

            if (response.data.id) {
                if (timerid) {
                    clearTimeout(timerid);
                }
                timerid = setTimeout(() => {
                    window.location.replace(`/activate?id=${response.data.id}`);
                }, 3000);
            }
        }
    }

    const [isSigninupWithLinkedin, setIsSigninupWithLinkedin] = useState(false)
    const [linkedinSignupData, setLinkedinSignupData] = useState(null)

    const { linkedInLogin } = useLinkedIn({
        clientId: '86x3397xbajc1d',
        redirectUri: `${window.location.origin}/linkedin`,
        scope: "r_emailaddress,r_liteprofile",
    
        onSuccess: (linkedinCode) => {
          setIsSigninupWithLinkedin(true)
    
          const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/signupLinkedin/`;
          const postBody = {
            'code': linkedinCode
          };
          const requestMetadata = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: new URLSearchParams(postBody)
          };
          fetch(recipeUrl, requestMetadata)
            .then(res => res.json())
            .then(response => {
                setLinkedinSignupData(response?.data)
                // Hack to remove warnings from name and last name inputs
                document.querySelector("#new-user-form > div > div.MuiBox-root.css-1wrmftn > div > div > div:nth-child(1) > div > div.MuiBox-root.css-opqdhz > div")?.remove()
                document.querySelector("#new-user-form > div > div.MuiBox-root.css-1wrmftn > div > div > div:nth-child(2) > div > div.MuiBox-root.css-opqdhz > div")?.remove()
            });
        },
        onError: (error) => { console.log(error) },
    });

    const [isCreatingProfile, setIsCreatingProfile] = useState(false)

    const [showBackendError, setShowBackendError] = useState(false)
    const [backendErrorToDisplay, setBackendErrorToDisplay] = useState("")

    const [selectedCountry, setSelectedCountry] = useState(null)
    const [showSelectedCountryError, setShowSelectedCountryError] = useState(false)
    
    const [uploadedCV, setUploadedCV] = useState(null)
    const [showUploadedCVError, setShowUploadedCVError] = useState(false)

    const getContent = formData => { // eslint-disable-line
        return <SignUp
            formData={formData}
            setSelectedCountry={setSelectedCountry}
            setShowSelectedCountryError={setShowSelectedCountryError}
            setUploadedCV={setUploadedCV}
            setShowUploadedCVError={setShowUploadedCVError}
            isSigninupWithLinkedin={isSigninupWithLinkedin}
            linkedinSignupData={linkedinSignupData}
            isCreatingProfile={isCreatingProfile}
        />
    }

    const signUpClick = async (values) => { // eslint-disable-line

        // if (!selectedCountry) return setShowSelectedCountryError(false)
        // if (!uploadedCV) return setShowUploadedCVError(true)

        await sleep(100);
        if ($(".MuiTypography-caption:visible").text().length === 0 && $("input[type='checkbox']").is(':checked')) {
            setIsCreatingProfile(true)
            $(".alertErrorBox")?.remove();

            const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/signupCandidate/`;
            const lastEmail = $("input[name=email]").val();
            ReactSession.set("lastEmail", lastEmail);

            const formCvPostData = new FormData();
            
            formCvPostData.append('email', values.email);
            formCvPostData.append('first_name', values.firstName);
            formCvPostData.append('last_name', values.lastName);
            formCvPostData.append('password', values.password);
            formCvPostData.append('password_confirmation', values.password);
            formCvPostData.append('file', uploadedCV);
            formCvPostData.append('country', selectedCountry);

            const utm = ReactSession.get("utm");
            if (utm) formCvPostData.append('utm', utm);
            
            if (code) formCvPostData.append('code', code);

            const requestMetadata = {
                method: 'POST',
                headers: {
                "Content-Disposition": `attachment; filename=cv.pdf`
                },
                body: formCvPostData
            };

            fetch(recipeUrl, requestMetadata)
                .then(res => res.json())
                .then(response => {
                    setIsCreatingProfile(false)

                    $(".suiAlertMsg")?.remove();
                    if (response.success) {
                        $(".suiAlertMsg")?.remove();
                        const successAlert = $($('.alertSuccessBox .alertSuccess').parent().html()).addClass("suiAlertMsg");
                        $(".suiAlertBox").parent().prepend(successAlert);

                        if (timerid) {
                            clearTimeout(timerid);
                        }

                        ReactGA.initialize(process.env.REACT_APP_DEFAULT_GA_KEY);
                        ReactGA.event({
                          category: "candidate_registered",
                          action: "candidate_registered",
                        });

                        timerid = setTimeout(() => {
                            window.location.replace(`/validated?id=${response.data.id}`);
                        }, 2000);
                    } else {
                        
                        setBackendErrorToDisplay(response.data.error)
                        setShowBackendError(true)

                        setTimeout(() => setShowBackendError(false), 2000);

                        if (timerid) {
                            clearTimeout(timerid);
                        }

                        if (response.data.confirmed) {
                            timerid = setTimeout(() => {
                                window.location.replace(`/t/login`);
                            }, 2000);
                        }

                        if (response.data.candidateExists && !response.data.confirmed && response.data.fromLever) {
                            timerid = setTimeout(() => {
                                window.location.replace(`/t/claim`);
                            }, 2000);
                        }
                    }
                });
        } else if (!$("input[type='checkbox']").is(':checked')) {
            $(".alertErrorBox div div").text("Agreement with Terms of Service is required");
            $(".alertErrorBox").show();
        }
    }

    const completeLinkedinSignup = async () => { // eslint-disable-line
        // Hack to remove warnings from name and last name inputs
        document.querySelector("#new-user-form > div > div.MuiBox-root.css-1wrmftn > div > div > div:nth-child(1) > div > div.MuiBox-root.css-opqdhz > div")?.remove()
        document.querySelector("#new-user-form > div > div.MuiBox-root.css-1wrmftn > div > div > div:nth-child(2) > div > div.MuiBox-root.css-opqdhz > div")?.remove()

        if (!selectedCountry) return setShowSelectedCountryError(false)
        if (!uploadedCV) return setShowUploadedCVError(true)

        if (!$("input[type='checkbox']").is(':checked')) {
            $(".suiAlertMsg")?.remove();
            $(".suiAlertMsg")?.remove();

            $(".alertErrorBox div div").text("Agreement with Terms of Service is required");
            return $(".alertErrorBox").show();
        }

        setIsCreatingProfile(true)

        const recipeUrl = `${process.env.REACT_APP_API_ROUTE}/api/users/signupLinkedinWithCV/`;
        const lastEmail = $("input[name=email]").val();
        ReactSession.set("lastEmail", lastEmail);

        const formCvPostData = new FormData();

        formCvPostData.append('email', linkedinSignupData?.email);
        formCvPostData.append('first_name', linkedinSignupData?.first_name);
        formCvPostData.append('last_name', linkedinSignupData?.last_name);
        formCvPostData.append('file', uploadedCV);
        formCvPostData.append('country', selectedCountry);
        formCvPostData.append('image_url', linkedinSignupData.image_url || MockCandidateAvatar);

        const utm = ReactSession.get("utm");
        if (utm) formCvPostData.append('utm', utm);
        
        if (code) formCvPostData.append('code', code);

        const requestMetadata = {
            method: 'POST',
            headers: {
            "Content-Disposition": `attachment; filename=cv.pdf`
            },
            body: formCvPostData
        };

        fetch(recipeUrl, requestMetadata)
            .then(res => res.json())
            .then(response => {
                setIsCreatingProfile(false)
                $(".suiAlertMsg")?.remove();

                if (response.success) {
                    $(".suiAlertMsg")?.remove();
                    const successAlert = $($('.alertSuccessBox .alertSuccess').parent().html()).addClass("suiAlertMsg");
                    $(".suiAlertBox").parent().prepend(successAlert);

                    if (timerid) {
                        clearTimeout(timerid);
                    }

                    manageLogin(response)
                } else {
                    $(".suiAlertMsg")?.remove();

                    setBackendErrorToDisplay(response.data.error)
                    setShowBackendError(true)

                    setTimeout(() => setShowBackendError(false), 2000);

                    if (timerid) {
                        clearTimeout(timerid);
                    }

                    if (response.data.confirmed) {
                        timerid = setTimeout(() => {
                            window.location.replace(`/t/login`);
                        }, 2000);
                    }

                    if (response.data.candidateExists && !response.data.confirmed && response.data.fromLever) {
                        timerid = setTimeout(() => {
                            window.location.replace(`/t/claim`);
                        }, 2000);
                    }
                }
            });
        
    }

    return (
        <>
            <SuiButton onClick={handleOpen} variant="gradient" color="info" size="small">
                Apply for this job
            </SuiButton>
            <Modal
                hideBackdrop
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <SuiBox sx={{
                    display: "flex",
                    position: 'relative',
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: "100vw", md: "80vw", lg: "80vw", xl: "60vw" },
                    height: { xs: "100vh", md: "auto" },
                    maxHeight: { md: "101vh" },
                    minHeight: { md: "40rem" },
                    background: "white",
                    border: 'none',
                    boxShadow: 16,
                    borderRadius: { xs: 0, md: 4 },
                    p: 3,
                }}>
                    <SuiBox>
                        {showUploadedCVError && <SuiAlert color="error">Please include your CV/Resume to continue.</SuiAlert>}
                        {showSelectedCountryError && <SuiAlert color="error">Please select your country of residence.</SuiAlert>}

                        <SuiBox className="alertErrorBox" display="none" style={{ maxWidth: "450px" }}>
                          <SuiAlert color="error" className="alertError">Please fill all the fields in this section before continuing.</SuiAlert>
                        </SuiBox>

                        {showBackendError && (
                            <SuiBox style={{ maxWidth: "450px" }}>
                                <SuiAlert color="error">{backendErrorToDisplay}</SuiAlert>
                            </SuiBox>
                        )}

                        {isSigninupWithLinkedin && (
                            <SuiTypography variant="body2" color="text" mt={1} textAlign="center">
                                Please add your CV and country of residence to finish.
                            </SuiTypography>
                        )}

                        <SuiBox className="alertSuccesModalBox" display="none" style={{ maxWidth: "450px" }}>
                          <SuiAlert color="info" className="alertSuccesModal">Please fill all the fields in this section before continuing.</SuiAlert>
                        </SuiBox>

                        <SuiBox display="flex" justifyContent="space-between" alignItems="center">
                            <SuiTypography id="child-modal-title" variant="overline" opacity={0.75}>
                                Join TECLA today
                            </SuiTypography>
                            <Icon onClick={handleClose} sx={{ cursor: "pointer", borderRadius: 1, backgroundColor: { xs: "#21c0fd", md: "#fff" }, color: { xs: "#fff", md: "#344767" } }}>close</Icon>
                        </SuiBox>

                        <SuiBox display="flex">
                            <SuiTypography id="modal-modal-description" variant="h2" fontWeight="bold">
                                Save your profile
                            </SuiTypography>
                        </SuiBox>

                        <SuiTypography variant="button" color="text" fontWeight="regular" opacity={0.75}>
                            Register to apply to jobs instantly next time
                        </SuiTypography>
                    </SuiBox>

                    <SuiBox display="flex">
                        <SuiBox sx={{ width: { xs: "100%", md: "45%" } }} mr={2}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={currentValidation}
                                onSubmit={handleSubmit}
                            >
                                {({ values, errors, touched, issubmitting }) => (
                                    <Form id={formId} autoComplete="off" type="POST" actions="#">
                                        <SuiBox>
                                            {getContent({
                                                values,
                                                touched,
                                                formField,
                                                errors,
                                            })}

                                            <SuiBox mt={4}>
                                                <SuiBox mt={1} display="flex" justifyContent="left" style={{ marginLeft: "15px" }}>
                                                    <Checkbox disabled={isCreatingProfile} />
                                                    <SuiTypography
                                                        variant="button"
                                                        fontWeight="regular"
                                                        sx={{ userSelect: "none" }}
                                                        name="terms"
                                                    >
                                                        I Agree with Tecla’s <a target="_blank" rel="noreferrer" href="https://www.tecla.io/tos/"><u>Terms of Service</u></a> and <a target="_blank" rel="noreferrer" href="https://www.tecla.io/privacy/"><u>Privacy policy</u></a>.
                                                    </SuiTypography>
                                                </SuiBox>
                                                <SuiBox display="flex" justifyContent="space-evenly" alignItems="center" flexWrap="wrap" mt={2} mb={1}>

                                                {isCreatingProfile ? // eslint-disable-line
                                                    <SuiBox mt={4} mb={1} display="flex" justifyContent="center" alignItems="center" >
                                                        <CircularProgress size={30} mr={5} />
                                                        <SuiTypography color="dark" fontWeight="bold" ml={3}>Creating your profile, please wait...</SuiTypography>
                                                    </SuiBox>
                                                :
                                                    !isSigninupWithLinkedin ?
                                                        <>
                                                            <SuiButton type="submit" size="small" issubmitting={issubmitting} onClick={() => signUpClick(values)} mr={2} gradiente sx={{ width: { xs: "100%", sm: "15rem", md: "initial" }, height: { xs: "44px", sm: "initial" }, marginBottom: 1, backgroundColor: "#feec1e" }}>
                                                                <SuiTypography color="black" variant="small" fontWeight="regular">Sign Up</SuiTypography>
                                                            </SuiButton>

                                                            <SuiButton variant="contained" color="info" size="small" gradient sx={{ width: { xs: "100%", sm: "15rem", md: "initial" }, height: { xs: "44px", sm: "initial" }, marginBottom: 1 }}>
                                                                <SuiTypography color="white" variant="small" fontWeight="regular" onClick={linkedInLogin}>Sign Up Using Linkedin</SuiTypography>
                                                            </SuiButton>
                                                        </>
                                                    :
                                                        <SuiButton type="submit" size="small" issubmitting={issubmitting} onClick={() => completeLinkedinSignup()} mr={2} gradiente sx={{ width: { xs: "100%", sm: "15rem", md: "initial" }, height: { xs: "44px", sm: "initial" }, marginBottom: 1, backgroundColor: "#feec1e" }}>
                                                            <SuiTypography color="black" variant="small" fontWeight="regular">Complete Sign Up</SuiTypography>
                                                        </SuiButton>
                                                }
                                                </SuiBox>
                                            </SuiBox>

                                        </SuiBox>
                                    </Form>
                                )}
                            </Formik>
                        </SuiBox>
                        
                        <SuiBox
                            position="absolute"
                            component="img"
                            src={backgroundSignin}
                            alt="coutries"
                            width="auto"
                            height="100%"
                            top="0"
                            right="5%"
                            sx={{ display: { xs: "none", md: "initial", xl: "initial" } }}
                        />
                    </SuiBox>
                </SuiBox>
            </Modal>
        </>
    );

}

export default SignUpModal;

/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { ReactSession } from 'react-client-session'
import { useState } from "react";
import { validateToken } from "utils/commonFunctions"; // eslint-disable-line
// @mui material components
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAlert from "components/SuiAlert";
// import Swal from "sweetalert2"


function EnableAISourcingModal({ showEnableAISourcingModal, setShowEnableAISourcingModal, jobId }) {

  ReactSession.setStoreType("localStorage")
  const token = ReactSession.get("token")

  const [showSpinner, setShowSpinner] = useState(false)
  const [showDoneIcon, setShowDoneIcon] = useState(false)
  const [showError, setShowError] = useState(false)

  const enableAISourcing = () => {
    setShowSpinner(true)

    fetch(`${process.env.REACT_APP_API_ROUTE}/api/jobs/${jobId}/setAutoSourcing/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      },
      body: JSON.stringify({ autoSourcing: 1 })
    })
    .then(async response => {
      const data = await response.json()
      if (data.success) {
        setShowSpinner(false)
        setShowDoneIcon(true)
      }
      else setShowError(true)
    })
    .catch(error => {
      setShowError(true)
      console.error('There was an error!', error)
    })

    setTimeout(() => {
      $(".toHideModal").hide()
      $(".toDisplayModal").show()
    }, 1000)

    setTimeout(() => {
      window.location.reload();
    }, 5000)
  }

  return (
    <Modal
        open={showEnableAISourcingModal}
        onClose={() => setShowEnableAISourcingModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SuiBox sx={{
            width: "650px",
            height: "auto",
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            borderRadius: { xs: 0, md: 4 },
            p: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        }} >

                {showDoneIcon && <SuiAlert color="info">Email Blast sent!</SuiAlert>}
                {showError && <SuiAlert color="error">There was an error, try again later</SuiAlert>}

                <SuiBox className="toHideModal">
                  <SuiTypography sx={{ my: 2.5 }} variant="h4">Send Email Blast for this Job?</SuiTypography>
                  <SuiTypography sx={{ my: 2.5 }} variant="body2">This feature will automatically send an email to all candidates identified by the system as a good fit, inviting them to apply for this position. You can view the list of invited candidates after the email has been sent.</SuiTypography>
                </SuiBox>

                <SuiBox sx={{ display: "none" }} className="toDisplayModal">
                  <SuiTypography sx={{ my: 2.5 }} variant="h4">Email Blast sent!</SuiTypography>
                  <SuiTypography sx={{ my: 2.5 }} variant="body2">You may now close this tab. The system will asynchronously search our platform for the best fitted candidates and initiate contact with them.</SuiTypography>
                </SuiBox>

                <SuiBox mt={2} mb={5} width='80%' mx='auto' display="flex">
                    <SuiButton
                      sx={{ mx: 1 }}
                      fullWidth
                      color="info"
                      variant="outlined"
                      disabled={showSpinner || showDoneIcon}
                      onClick={() => setShowEnableAISourcingModal(false)}
                      className="toHideModal"
                    >
                      Cancel
                    </SuiButton>

                    <SuiBox className="toHideModal">
                      {/* eslint-disable-next-line */}
                      {showSpinner ?
                          <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><CircularProgress size={20} /></SuiBox>
                          :
                          showDoneIcon ?
                              <SuiBox mx={5} display="flex" justifyContent="center" alignItems="center"><DoneIcon size={20} /></SuiBox>
                              :
                              <SuiButton
                                sx={{ mx: 1, width: "250px", margin: "0 auto", textAlign: "center" }}
                                fullWidth
                                disabled={showSpinner || showDoneIcon}
                                color="info"
                                variant="gradient"
                                onClick={() => enableAISourcing()}
                              >
                                Send
                              </SuiButton>
                      }
                    </SuiBox>
                </SuiBox>
        </SuiBox>
    </Modal>
  )
}

// Typechecking props for the ProfilesList
EnableAISourcingModal.propTypes = {
  showEnableAISourcingModal: PropTypes.bool.isRequired,
  setShowEnableAISourcingModal: PropTypes.func.isRequired,
  jobId: PropTypes.number.isRequired,
};
  

export default EnableAISourcingModal